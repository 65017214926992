import { gql } from "graphql-request";
import { getGraphQLClientInstance } from "../clients/clientFactory";

/**
 * UserRoleMappingsService graphql endpoints
 */
export class UserRoleMappingsService {
  /**
   * To call the add user client roles endpoint
   */
  async addClientLevelRoleMapping(userId: string, clientId: string, { id, name }: { id: string; name: string }) {
    const query = gql`
      mutation ($clientId: String!, $userId: String!, $roleInput: RoleInput!) {
        addClientLevelRoleMapping(userId: $userId, clientId: $clientId, roleInput: $roleInput)
      }
    `;

    const { addClientLevelRoleMapping } = await getGraphQLClientInstance({ secured: true }).request(query, {
      userId: userId,
      clientId: clientId,
      roleInput: {
        id: id,
        name: name
      }
    });

    return addClientLevelRoleMapping;
  }

  /**
   * To call the delete user client roles endpoint
   */
  async deleteClientLevelRoleMapping(userId: string, clientId: string, { id, name }: { id: string; name: string }) {
    const query = gql`
      mutation ($userId: String!, $clientId: String!, $roleInput: RoleInput!) {
        deleteClientLevelRoleMapping(userId: $userId, clientId: $clientId, roleInput: $roleInput)
      }
    `;

    const { deleteClientLevelRoleMapping } = await getGraphQLClientInstance({ secured: true }).request(query, {
      userId: userId,
      clientId: clientId,
      roleInput: {
        id: id,
        name: name
      }
    });

    return deleteClientLevelRoleMapping;
  }
}

export const userRoleMappingsService = new UserRoleMappingsService();
