import { createUseStyles } from "react-jss";

/**
 * Styles for the user management toolbar
 */
export const useMemberManagerToolbarStyles = createUseStyles({
  menuBarCls: {
    marginBottom: "2em"
  }
});
