import { gql } from "graphql-request";
import { getGraphQLClientInstance } from "../clients/clientFactory";

/**
 * AuthService graphql endpoints
 */
export class AuthService {
  /**
   * To call the update user profile endpoint
   */
  async updateProfile({ firstName, lastName }: { firstName: string; lastName: string }) {
    const mutation = gql`
      mutation ($firstName: String!, $lastName: String!) {
        updateProfile(updateProfileInput: { firstName: $firstName, lastName: $lastName })
      }
    `;

    const { updateProfile } = await getGraphQLClientInstance({ secured: true }).request(mutation, {
      firstName: firstName,
      lastName: lastName
    });

    return updateProfile;
  }

  /**
   * To call the update user password endpoint
   */
  async updatePassword(newPassword: string) {
    const mutation = gql`
      mutation ($newPassword: String!) {
        updatePassword(newPassword: $newPassword)
      }
    `;

    const { updatePassword } = await getGraphQLClientInstance({ secured: true }).request(mutation, {
      newPassword: newPassword
    });

    return updatePassword;
  }

  /**
   * To call the update user attribute endpoint
   */
  async updateUserAttribute({ attributeName, attributeValue }: { attributeName: string; attributeValue: string }) {
    const mutation = gql`
      mutation ($attributeName: String!, $attributeValue: String!) {
        updateAttribute(attributeName: $attributeName, attributeValue: $attributeValue)
      }
    `;

    const { updateAttribute } = await getGraphQLClientInstance({ secured: true }).request(mutation, {
      attributeName: attributeName,
      attributeValue: attributeValue
    });

    return updateAttribute;
  }
}

export const authService = new AuthService();
