import { observer } from "mobx-react-lite";
import React from "react";
import { Route } from "react-router-dom";
import KeycloakUserService from "../services/KeycloakUserService";

/**
 * Observable-based routing that checks if the user has has logged in and has the permission to access a certain page
 */
const AuthRoute = observer(({ exact = false, path, Component, requiredAuthentication = false }) => {
  return (
    <Route
      path={path}
      exact={exact}
      render={(props) => {
        if (requiredAuthentication) {
          if (KeycloakUserService.isLoggedIn()) {
            if (KeycloakUserService.hasXAdminRole()) {
              return <Component {...props} />;
            } else {
              return KeycloakUserService.doLogout();
            }
          } else {
            return KeycloakUserService.doLogin();
          }
        }
      }}
    />
  );
});

export default AuthRoute;
