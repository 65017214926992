import { gql } from "graphql-request";
import { getGraphQLClientInstance } from "../clients/clientFactory";

/**
 * ClientsService graphql endpoints
 */
export class ClientsService {
  /**
   * To call the get Keycloak client roles endpoint
   */
  async getClientWithRoles(clientId: string) {
    const query = gql`
      query ($clientId: String!) {
        clientWithRoles(clientId: $clientId) {
          id
          clientId
          keycloakRoles {
            id
            name
          }
        }
      }
    `;

    const { clientWithRoles } = await getGraphQLClientInstance({ secured: true }).request(query, {
      clientId: clientId
    });

    return clientWithRoles;
  }
}

export const clientsService = new ClientsService();
