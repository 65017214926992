import { createUseStyles } from "react-jss";

/**
 * Styles for the top menu navigation bar
 */
export const useTopMenuNavigationStyles = createUseStyles({
  headerCls: {
    backgroundColor: "#074E91",
    width: "100%"
  },
  searchFieldCls: {
    marginLeft: "50px",
    width: "60%",
    border: "none",
    backgroundColor: "#053666",
    "& input": {
      color: "white",
      fontSize: "1em",
      backgroundColor: "#053666",
      "&::placeholder": {
        color: "#A2C2DE"
      }
    }
  },
  avatarCls: {
    backgroundColor: "#031B33"
  },
  menuItemCls: {
    fontSize: "16px"
  },
  menuItemIconCls: {
    fontSize: "16px !important"
  }
});
