import { Button, Form, Input, message, Modal, notification, Space } from "antd";
import React from "react";
import { useHandleUpdatePassword } from "../../../hooks/AuthHooks";

/**
 * The change password pop up form
 */
const ChangePasswordModal = ({ visible, onCancel }) => {
  const [handleUpdatePassword, { loading }] = useHandleUpdatePassword();

  // On form submit button clicked
  const onFinish = ({ newPassword }) => {
    handleUpdatePassword(
      newPassword,
      () => {
        notification.success({
          message: "Updated password successfully!",
          placement: "topRight"
        });
        onCancel();
      },
      (errorMessages) => {
        errorMessages.forEach((errorMessage) => {
          message.error(errorMessage).then();
        });
      }
    );
  };

  return (
    <Modal title="Change password" visible={visible} footer={null} closable={false} onCancel={onCancel}>
      <Form layout="vertical" name="basic" onFinish={onFinish}>
        <Form.Item
          label="New password"
          name="newPassword"
          rules={[
            { required: true, message: "✕ Please input your password!" },
            {
              pattern: /\d/,
              message: "✕ Must have at least one number"
            },
            {
              pattern: /.*[A-Z].*/,
              message: "✕ Must have at least one capital letter"
            },
            () => ({
              validator(_, value) {
                if (!value || value.length >= 8) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("✕ Must have at least 8 characters"));
              }
            })
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Confirm password"
          name="confirmPassword"
          rules={[
            { required: true, message: "Please confirm your password!" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("The two passwords that you entered do not match!"));
              }
            })
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item style={{ textAlign: "right" }}>
          <Space>
            <Button type="default" onClick={onCancel}>
              Cancel
            </Button>
            <Button id="save_password_button" type="primary" htmlType="submit" loading={loading}>
              Save
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ChangePasswordModal;
