import useSWRImmutable from "swr/immutable";
import { clientsService } from "../services/ClientsService";

/**
 * To get the available client roles
 */
export const useClientWithRoles = (clientId) => {
  const { data, error } = useSWRImmutable(`Client-${clientId}WithRoles`, () =>
    clientsService.getClientWithRoles(clientId).then((result) => result)
  );

  return { data, error, loading: !data && !error };
};
