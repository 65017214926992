import { GraphQLClient } from "graphql-request";
import KeycloakUserService from "../services/KeycloakUserService";

const { REACT_APP_GRAPHQL_SERVER_ENDPOINT } = process.env;

/**
 * Get the graphQL client for handling requests
 */
export const getGraphQLClientInstance = ({ secured } = { secured: false }) => {
  const graphQLClient = new GraphQLClient(REACT_APP_GRAPHQL_SERVER_ENDPOINT);

  if (secured) {
    try {
      const accessToken = KeycloakUserService.getToken();
      graphQLClient.setHeader("Authorization", `Bearer ${accessToken}`);
    } catch (e) {
      throw new Error("Unable to authorize!");
    }
  }
  return graphQLClient;
};
