import { createUseStyles } from "react-jss";

/**
 * Styles for the dashboard (usage report) page
 */
export const useDashboardPageStyles = createUseStyles({
  mainContentCls: {
    padding: "2em"
  },
  titleCls: {
    fontSize: "20px",
    fontWeight: "bold"
  }
});
