import { ClientError } from "graphql-request";
import { useCallback, useState } from "react";
import { authService } from "../services/AuthService";

/**
 * To handle update user profile
 */
export const useHandleUpdateProfile = () => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState([]);

  const handleUpdateProfile = useCallback(({ firstName, lastName }, onSuccess, onError) => {
    authService
      .updateProfile({ firstName, lastName })
      .then(() => {
        setSuccess(true);
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        setError(error);
        if (error instanceof ClientError) {
          if (onError) {
            const errorMessages = [];
            error.response.errors.forEach((error) => {
              errorMessages.push(error.message);
            });
            onError(errorMessages);
          }
        }
      });
  }, []);

  return [handleUpdateProfile, { success, error, loading: !success && !error }];
};

/**
 * To handle update user password
 */
export const useHandleUpdatePassword = () => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState([]);

  const handleUpdatePassword = useCallback((newPassword, onSuccess, onError) => {
    authService
      .updatePassword(newPassword)
      .then(() => {
        setSuccess(true);
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        setError(error);
        if (error instanceof ClientError) {
          if (onError) {
            const errorMessages = [];
            error.response.errors.forEach((error) => {
              errorMessages.push(error.message);
            });
            onError(errorMessages);
          }
        }
      });
  }, []);

  return [handleUpdatePassword, { success, error, loading: !success && !error }];
};

/**
 * To handle update user attribute
 */
export const useHandleUpdateUserAttribute = () => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState([]);

  const handleUpdateUserAttribute = useCallback((attributeName, attributeValue, onSuccess, onError) => {
    authService
      .updateUserAttribute({ attributeName, attributeValue })
      .then(() => {
        setSuccess(true);
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        setError(error);
        if (error instanceof ClientError) {
          if (onError) {
            const errorMessages = [];
            error.response.errors.forEach((error) => {
              errorMessages.push(error.message);
            });
            onError(errorMessages);
          }
        }
      });
  }, []);
  return [handleUpdateUserAttribute, { success, error, loading: !success && !error }];
};
