import { LogoutOutlined, ProfileOutlined, SearchOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Col, Dropdown, Image, Input, Layout, Menu, message, Row } from "antd";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import LexxLogo from "../../../assets/images/lexx-logo.png";
import { useTopMenuNavigationStyles } from "./styles";
import KeycloakUserService from "../../../services/KeycloakUserService";

const { Header } = Layout;

/**
 * The top menu navigation bar
 */
const TopMenuNavigation = () => {
  const history = useHistory();
  const { headerCls, searchFieldCls, avatarCls, menuItemCls, menuItemIconCls } = useTopMenuNavigationStyles();
  const [profilePhotoUrl, setProfilePhotoUrl] = useState("");

  // Get the user profile photo and display it
  useEffect(() => {
    const profilePhotoUrlFromKeycloak = KeycloakUserService.getProfilePhotoUrl();
    setProfilePhotoUrl(profilePhotoUrlFromKeycloak);
  });

  // Go to the root page
  const onLogoClick = () => {
    history.push("/");
  };

  // Go to the profile page
  const onProfileItemClick = () => {
    history.push("/profile");
  };

  // Logout the user
  const onLogoutItemClick = () => {
    KeycloakUserService.doLogout();
  };

  const menu = (
    <Menu>
      <Menu.Item
        className={menuItemCls}
        key="1"
        icon={<ProfileOutlined className={menuItemIconCls} />}
        onClick={onProfileItemClick}
      >
        Profile
      </Menu.Item>
      <Menu.Item
        className={menuItemCls}
        key="3"
        icon={<LogoutOutlined className={menuItemIconCls} />}
        onClick={onLogoutItemClick}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Header className={headerCls}>
      <Row align="middle" justify="space-between">
        <Col span={16}>
          <Row align="middle">
            <Image width={120} src={LexxLogo} preview={false} onClick={onLogoClick} />
          </Row>
        </Col>
        <Col>
          <span>
            <Dropdown overlay={menu} trigger={["click"]}>
              {profilePhotoUrl ? (
                <Avatar className={avatarCls} size="large" src={profilePhotoUrl} crossOrigin="" />
              ) : (
                <Avatar className={avatarCls} size="large" icon={<UserOutlined />} />
              )}
            </Dropdown>
          </span>
        </Col>
      </Row>
    </Header>
  );
};

export default TopMenuNavigation;
