import { createUseStyles } from "react-jss";

/**
 * Styles for the helper toolbar
 */
export const useHelperToolbarStyles = createUseStyles({
  toolbarCls: {
    backgroundColor: "#fafafa",
    padding: "15px !important"
  },
  iconCls: {
    fontSize: "30px !important"
  },
  titleCls: {
    fontSize: "20px",
    fontWeight: "bold"
  }
});
