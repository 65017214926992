import { createContext, useContext } from "react";
import { ReportGenerationStore } from "./ReportGenerationStore";

const reportGenerationStore = new ReportGenerationStore();

// To create a local storage
export const createStores = async () => {
  return {
    reportGenerationStore: reportGenerationStore
  };
};

// To create a local store context using the report generation store
export const StoreContext = createContext({
  reportGenerationStore: reportGenerationStore
});

// Wrap the StoreContext using useContext hook, so that the store context can be accessed in all components
export const useStores = () => useContext(StoreContext);
