import { createUseStyles } from "react-jss";

/**
 * Styles for the side navigation menu
 */
export const useSideNavigationMenuStyles = createUseStyles({
  sideBarContainerCls: {
    backgroundColor: "#fff"
  }
});
