import Keycloak from "keycloak-js";

const _kc = new Keycloak("/keycloak.json");

const { REACT_APP_USER_PROFILE_PHOTO_URL_KEYCLOAK_ATTRIBUTE_NAME, REACT_APP_X_ADMIN_DEFAULT_ROLE_NAME } = process.env;

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  _kc
    .init({
      onLoad: "login-required",
      checkLoginIframe: false,
      // silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html",
      pkceMethod: "S256"
    })
    .then((authenticated) => {
      onAuthenticatedCallback();
    })
    .catch(console.error);
};

// Do Keycloak login
const doLogin = _kc.login;

// Do Keycloak logout
const doLogout = _kc.logout;

// Get Keycloak user access token
const getToken = () => _kc.token;

// Get Keycloak user group id
const getGroupId = () => _kc.idTokenParsed?.groups[0];

// Check if the user is logged in
const isLoggedIn = () => !!_kc.token;

// Update the Keycloak user access token
const updateToken = (successCallback) => _kc.updateToken(5).then(successCallback).catch(doLogin);

// Get username
const getUsername = () => _kc.tokenParsed?.preferred_username;

// Check if user has a certain role
const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

// Get the user roles
const getRoles = () => _kc.tokenParsed?.realm_access.roles;

// Get the user ID
const getUserID = () => _kc.tokenParsed?.sub;

// Check if the user is authenticated
const isAuthenticated = () => _kc.authenticated;

// Check if the user has xAdmin role
const hasXAdminRole = () => _kc.tokenParsed?.realm_access.roles.includes(REACT_APP_X_ADMIN_DEFAULT_ROLE_NAME);

// Get the user profile photo url
const getProfilePhotoUrl = () => _kc.idTokenParsed[REACT_APP_USER_PROFILE_PHOTO_URL_KEYCLOAK_ATTRIBUTE_NAME];

const KeycloakUserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  getRoles,
  getUserID,
  isAuthenticated,
  hasXAdminRole,
  getProfilePhotoUrl,
  getGroupId
};

export default KeycloakUserService;
