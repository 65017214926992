import { Button, Col, Divider, Form, Input, Layout, message, notification, Row, Space, Typography } from "antd";
import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { useHandleUpdateUser, useUser } from "../../../hooks/UserHooks";
import RoleManagementTable from "../../organisms/RoleManagementTable/RoleManagementTable";
import SideNavigationMenu from "../../organisms/SideNavigationMenu/SideNavigationMenu";
import TopMenuNavigation from "../../organisms/TopMenuNavigation/TopMenuNavigation";
import { userEditUserPageStyles } from "./styles";

const { Content } = Layout;
const { Text } = Typography;

/**
 * The edit user page
 */
const EditUserPage = () => {
  const { id } = useParams();

  const { data } = useUser(id);
  const [handleUpdateUser] = useHandleUpdateUser();

  const [form] = Form.useForm();

  // Populate the user information
  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [form, data]);

  const history = useHistory();

  // Handle go back to previous page
  const onBackButtonClick = () => {
    history.goBack();
  };

  // Handle save user information changes
  const onFinish = ({ firstName, lastName }) => {
    handleUpdateUser(
      { id, firstName, lastName },
      () => {
        notification.success({
          message: "Updated profile successfully!",
          placement: "topRight"
        });
      },
      (errorMessages) => {
        errorMessages.forEach((errorMessage) => {
          message.error(errorMessage).then();
        });
      }
    );
  };

  const { mainContentCls, titleCls } = userEditUserPageStyles();

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <TopMenuNavigation />
      <Layout>
        <SideNavigationMenu />
        <Content className={mainContentCls}>
          <Form form={form} name="control-ref" onFinish={onFinish} layout="vertical">
            <Row align="middle" justify="space-between">
              <Text className={titleCls}>{data ? `${data.firstName} ${data.lastName}` : null}</Text>
            </Row>
            <Divider />
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item name="username" label="Username">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="email" label="Email">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="firstName"
                  label="Given name(s)"
                  rules={[{ required: true, message: "✕ Please input your first name!" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="lastName"
                  label="Surname"
                  rules={[{ required: true, message: "✕ Please input your last name!" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle" justify="end">
              <Space direction="horizontal">
                <Button type="default" onClick={onBackButtonClick}>
                  Back
                </Button>
                <Button type="primary" htmlType="submit">
                  Save changes
                </Button>
              </Space>
            </Row>
          </Form>
          <Divider />
          <RoleManagementTable userId={id} />
        </Content>
      </Layout>
    </Layout>
  );
};

export default EditUserPage;
